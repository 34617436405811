import React, { useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Menu,
  TableCell,
  TableRow,
  Text,
  Tip,
} from "grommet";
import {
  FormNextLink,
  LinkNext,
  Trash,
  Clock,
  Down,
  Up,
  StatusWarning,
} from "grommet-icons";
import moment from "moment-timezone";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { PasscodeService, EKeyService } from "@services/index";

import "./AccessList.scss";

const AccessList = ({
  title,
  keys,
  bookingId,
  hotel,
  onRefresh,
  collapsing,
}) => {
  const isOracode = hotel?.lockType === "oracode";

  const handleDeleteAccess = useCallback(
    async (access) => {
      presentLoading();
      try {
        let response;
        if (access.keyboardPwd) {
          response = await PasscodeService.delete(bookingId, access._id);
        } else {
          response = await EKeyService.delete(access._id);
        }
        dismissLoading();
        presentToastSuccess(response.data?.message || "Access deleted");
        onRefresh();
      } catch (error) {
        dismissLoading();
        presentToastErrorContent(error);
      }
    },
    [onRefresh]
  );

  const handleGoToBooking = (id) => {
    window.location.href = id;
  };

  const table = useMemo(
    () => (
      <table style={{ width: "fit-content" }}>
        <tbody>
          {keys.map((key) => (
            <TableRow key={key._id} align="center">
              <TableCell alignContent="center" direction="row" gap="small">
                {moment().isAfter(key.endDate) && (
                  <Tip
                    content={
                      <Box>
                        <Text>This access has expired.</Text>
                        <Text>
                          Please remove it or mark the related reservation as
                          checked out in your PMS.
                        </Text>
                      </Box>
                    }
                  >
                    <StatusWarning color="status-warning" size="20px" />
                  </Tip>
                )}
                <Text>
                  {key.door?.type === "area"
                    ? key.door?.name
                    : `Room ${key.door?.name}:`}
                </Text>
              </TableCell>
              {key.keyboardPwd && (
                <TableCell>
                  <Box
                    border={{ size: "small", color: "text" }}
                    round
                    pad={{ horizontal: "6px" }}
                    align="center"
                  >
                    <Text>{key.keyboardPwd}</Text>
                  </Box>
                </TableCell>
              )}
              <TableCell align="center" direction="row">
                <Text size="xsmall" color="status-ok">
                  {moment.tz(key.startDate, hotel.timezone).format("MMM D LT")}
                </Text>
                <FormNextLink />
                <Text
                  size="xsmall"
                  color="status-error"
                  margin={{ right: "8px" }}
                >
                  {moment.tz(key.endDate, hotel.timezone).format("MMM D LT")}
                </Text>
                {key.created && (
                  <Tip
                    content={`Created on ${moment
                      .tz(key.created, hotel?.timezone)
                      .format("lll")}`}
                  >
                    <Clock size="small" />
                  </Tip>
                )}
              </TableCell>
              {!isOracode && (
                <TableCell gap="small" direction="row">
                  {(!key.bookings ||
                    key.bookings.some((x) => x._id === bookingId)) && (
                    <Tip
                      content={"Remove this access only"}
                      dropProps={{ align: { left: "right" } }}
                      margin={{ bottom: "2px" }}
                    >
                      <Button
                        icon={<Trash size="small" />}
                        onClick={() => {
                          handleDeleteAccess(key);
                        }}
                        primary
                        color="status-error"
                        style={{ padding: "5px" }}
                        disabled={
                          key.keyboardPwd &&
                          key.keyboardPwd.length !==
                            (hotel.numberOfLastDigitsUsed || 4) &&
                          !moment().isAfter(key.endDate)
                        }
                        title={
                          key.keyboardPwd &&
                          key.keyboardPwd.length !==
                            (hotel.numberOfLastDigitsUsed || 4) &&
                          !moment().isAfter(key.endDate)
                            ? "Offline codes cannot be removed"
                            : undefined
                        }
                      />
                    </Tip>
                  )}
                  {key.bookings.some((x) => x._id !== bookingId) &&
                    (key.bookings.filter((x) => x._id !== bookingId).length >
                    1 ? (
                      <Tip
                        content={"Multiple reservations"}
                        dropProps={{ align: { left: "right" } }}
                        margin={{ bottom: "2px" }}
                      >
                        <Menu
                          label={
                            <Button
                              icon={<LinkNext size="small" />}
                              primary
                              color="blue-1"
                              style={{ padding: "5px" }}
                            />
                          }
                          items={key.bookings
                            .filter((x) => x._id !== bookingId)
                            .map((booking) => ({
                              label: `Go to ${booking.bookingRef}`,
                              onClick: () => handleGoToBooking(booking._id),
                            }))}
                          className="access-menu"
                        />
                      </Tip>
                    ) : (
                      key.bookings[0]._id !== bookingId && (
                        <Tip
                          content={`Go to ${key.bookings[0].bookingRef}`}
                          dropProps={{ align: { left: "right" } }}
                        >
                          <Button
                            icon={<LinkNext size="small" />}
                            onClick={() => {
                              handleGoToBooking(key.bookings[0]._id);
                            }}
                            primary
                            color="blue-1"
                            style={{ padding: "5px" }}
                          />
                        </Tip>
                      )
                    ))}
                </TableCell>
              )}
            </TableRow>
          ))}
        </tbody>
      </table>
    ),
    [keys, hotel.timezone, isOracode, bookingId, handleDeleteAccess]
  );

  const header = useMemo(
    () => (
      <Heading level={4} margin="none">
        {title}
      </Heading>
    ),
    [title]
  );

  const [showHideIcon, setShowHideIcon] = React.useState(
    <Down color="brand" />
  );

  const handleOnActive = (indexes) => {
    setShowHideIcon(
      indexes.includes(0) ? <Up color="brand" /> : <Down color="brand" />
    );
  };

  return (
    <>
      {keys?.length > 0 ? (
        !collapsing ? (
          <>
            {header} {table}
          </>
        ) : (
          <Accordion onActive={handleOnActive}>
            <AccordionPanel
              header={
                <Box direction="row" gap="small">
                  {header} {showHideIcon}
                </Box>
              }
            >
              {table}
            </AccordionPanel>
          </Accordion>
        )
      ) : (
        <>
          {header}
          <Text>No {title}</Text>
        </>
      )}
    </>
  );
};

export default AccessList;
