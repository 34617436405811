import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  CheckBox,
  Select,
  FormField,
  TextInput,
  TextArea,
} from "grommet";
import HotelService from "../../services/hotel.service";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { useFetchHotel } from "./redux/hooks";
import {
  LANGUAGE_OPTIONS_LIST,
  LOCK_TYPE_OPTIONS_LIST,
  PRONOUNS_FR_OPTIONS_LIST,
  getFilteredPropsObject,
} from "@common/utils";
import { HourInput } from "@shared";

const adminDefaultValues = {
  disabled: false,
  language: "en",
  pronounsFr: "LE",
  sendMobileKeyQuietly: false,
  demo: false,
  lockType: "ttlock",
  messagingDisabled: false,
  useUpgradeModules: false,
  disableBatteryCheck: false,
  adminPin: undefined,
  renewSubscription: undefined,
  aiEnabled: false,
  hasWifiGateway: false,
  usePasscodes: false,
  useRandomCode: false,
  numberOfLastDigitsUsed: false,
  partialSetup: false,
  optOutMarketingOptionEnabled: true,
  checkinStepsRequired: false,
  registrationEnabled: false,
  registrationText: "",
  pmsId: "",
  autoSendWalkinTime: "",
  commonAreaFixedPasscode: "",
  commonAreaFixedLabel: null,
  useFallbackOfflineCodes: true,
  customCheckinSMS: "",
  customCheckinEmail: "",
  sector: "2",
  inAppChatEnabled: false,
};

export default function SettingsAdmin() {
  const { hotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(adminDefaultValues);

  useEffect(() => {
    setFormValues({ ...adminDefaultValues, ...hotel });
  }, [hotel]);

  const handleCancel = () => {
    setFormValues({ ...adminDefaultValues, ...hotel });
  };

  function handleInputChange({ target: { name, value } }) {
    var values = { ...formValues, [name]: value };
    setFormValues(values);
  }

  function handleHourInputChange({ name, value }) {
    var values = { ...formValues, [name]: value };
    setFormValues(values);
  }

  function handleCheckboxChange({ target: { name, checked } }) {
    setFormValues({ ...formValues, [name]: checked });
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const request = getFilteredPropsObject(formValues, adminDefaultValues);
    HotelService.updateHotel(request).then(
      (response) => {
        if (response.data.ok) {
          presentToastSuccess("Information Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const renderCheckbox = (label, name, disabled) => (
    <CheckBox
      name={name}
      checked={formValues[name]}
      onChange={handleCheckboxChange}
      label={label}
      disabled={disabled}
    />
  );

  const renderSelect = (label, name, options) => (
    <FormField label={label} width="medium">
      <Select
        size="medium"
        labelKey="label"
        valueKey={{ key: "value", reduce: true }}
        placeholder="Select"
        value={formValues[name]}
        options={options}
        onChange={({ value: nextValue }) =>
          setFormValues({ ...formValues, [name]: nextValue })
        }
      />
    </FormField>
  );

  const renderTextInput = (label, name) => (
    <FormField label={label} width="medium">
      <TextInput
        name={name}
        value={formValues[name] || ""}
        onChange={handleInputChange}
      />
    </FormField>
  );

  const renderTextArea = (label, name, disabled, height = 300) => (
    <FormField label={label}>
      <TextArea
        name={name}
        value={formValues[name] || ""}
        onChange={handleInputChange}
        style={{ height }}
        disabled={disabled}
      />
    </FormField>
  );

  return (
    <div>
      <Heading level={3} margin="small">
        Account
      </Heading>

      <Box width="large" gap="medium" background="light-2" round pad="medium">
        {renderTextInput("PMS ID", "pmsId")}
        {renderCheckbox("Disabled Account", "disabled")}
        {renderCheckbox("Onboarding in progress (demo)", "demo")}
        {renderTextInput("Renew Subscription", "renewSubscription")}
      </Box>

      <Heading level={3} margin="small">
        Features
      </Heading>

      <Box width="large" gap="medium" background="light-2" round pad="medium">
        {renderSelect("Language", "language", LANGUAGE_OPTIONS_LIST)}
        {renderSelect(
          "Pronouns (French)",
          "pronounsFr",
          PRONOUNS_FR_OPTIONS_LIST
        )}
        {renderSelect("Lock type", "lockType", LOCK_TYPE_OPTIONS_LIST)}
        <FormField label="Auto Send Walkin Time" width="medium">
          <HourInput
            name="autoSendWalkinTime"
            value={formValues.autoSendWalkinTime}
            onChange={handleHourInputChange}
            timezone={hotel.timezone}
            min="08:00"
            filter={(o) => o.value === "" || o.value?.endsWith(":00")}
            withEmptyLabel="Disabled"
          />
        </FormField>
        {renderCheckbox("Send Mobile Key Quietly", "sendMobileKeyQuietly")}
        {renderCheckbox("Use Upgrade Modules", "useUpgradeModules")}
        {renderCheckbox("Disable Battery Check", "disableBatteryCheck")}
        {renderCheckbox("Messaging Disabled", "messagingDisabled")}
        {renderCheckbox("In App Chat Enabled", "inAppChatEnabled")}
        {renderCheckbox(
          "AI Enabled",
          "aiEnabled",
          !formValues.inAppChatEnabled
        )}
        {renderCheckbox("Has Wifi Gateway", "hasWifiGateway")}
        {renderCheckbox("Use Passcodes", "usePasscodes")}
        {renderCheckbox("Force Random Codes", "useRandomCode")}
        {renderTextInput(
          "Number Of Last Digits Used",
          "numberOfLastDigitsUsed"
        )}
        {renderCheckbox(
          "Use fallback offline codes",
          "useFallbackOfflineCodes"
        )}
        {renderCheckbox(
          "Opt Out Marketing Option Enabled",
          "optOutMarketingOptionEnabled"
        )}
        {renderCheckbox("All Check-in Steps Required", "checkinStepsRequired")}
        {renderCheckbox("Partial Setup", "partialSetup")}
        {renderTextInput("Admin Pin (Master cards encoding)", "adminPin")}
        {renderTextInput("Encoder Sector", "sector")}
        {renderTextInput(
          `Common Area Fixed Label (en=[x] fr=[x])`,
          "commonAreaFixedLabel"
        )}
        {renderTextInput(
          "Common Area Fixed Access Code",
          "commonAreaFixedPasscode"
        )}
        {renderCheckbox("Registration Enabled", "registrationEnabled")}
        {renderTextArea(
          "Registration Text",
          "registrationText",
          !formValues.registrationEnabled,
          600
        )}
      </Box>
      <Heading level={3} margin="small">
        Custom Messages
      </Heading>
      <Box width="large" gap="medium" background="light-2" round pad="medium">
        {renderTextArea("Custom Checkin SMS", "customCheckinSMS", false, 300)}
        {renderTextArea(
          "Custom Checkin Email",
          "customCheckinEmail",
          false,
          300
        )}
      </Box>
      <Box direction="row" justify="between" margin={{ top: "medium" }}>
        <Button label="Cancel" onClick={handleCancel} />
        <Button type="submit" label="Update" primary onClick={handleOnSubmit} />
      </Box>
    </div>
  );
}
